// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { credentials } from '@devsta/client-auth';
import { replaceBucketKeysWithUrls } from '@devsta/client-aws-s3';
import type { RouterHistory as History } from 'react-router-dom';
import { CreditsInfo } from '@devsta/common-react';
import NotificationsWrapper from './NotificationsWrapper';
import NotificationsSummary from './NotificationsSummary';
import UserWrapper from './UserWrapper';
import UserSummary from './UserSummary';
import CreditsWrapper from './CreditsWrapper';
import HelpButton from './HelpButton';
import SwitchOrganisationModal from './SwitchOrganisationModal';
import { redirectToSingleLogout, getSSOLocalStore, clearSSOLocalStore } from '../../../../utils';
import '../styles/_global-header.scss';

type GlobalHeaderProps = {
  showSwitchOrganisationModal: Function,
  appSwitcherVisible: boolean,
  onCloseSwitchOrganisationModal: Function,
  apolloClient: Object,
  selectedOrganisation: Object,
  notifications: Object,
  history: History
};

type GlobalHeaderState = {
  userId: string,
  userSummaryVisible: boolean,
  notificationsSummaryVisible: boolean,
  fullName: string,
  imageUrl: string,
  email: string,
  isGhosting: boolean,
  original: Object,
  origURL: string
};

export default class GlobalHeader extends Component<GlobalHeaderProps, GlobalHeaderState> {
  userSummaryNode: ?HTMLElement;

  notificationsSummaryNode: ?HTMLElement;

  userProfileHeaderNode: ?HTMLElement;

  notificationsHeaderNode: ?HTMLElement;

  constructor(props: GlobalHeaderProps) {
    super(props);

    const { userId, firstName, lastName, imageUrl, email } = credentials.get().user;
    const { original = null, origURL = '' } = credentials.get();

    const fullName = `${firstName} ${lastName}`;

    this.state = {
      userId,
      fullName,
      imageUrl,
      email,
      userSummaryVisible: false,
      notificationsSummaryVisible: false,
      isGhosting: Boolean(original),
      original,
      origURL
    };
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleGlobalClick);

    this.getPreSignedImageUrl();
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleGlobalClick);
  };

  getPreSignedImageUrl = async () => {
    const { selectedOrganisation: { organisationId }, apolloClient } = this.props;
    const { imageUrl } = this.state;

    try {
      const { imageUrl: preSignedImageUrl } = await replaceBucketKeysWithUrls({
        apiClient: apolloClient,
        data: { imageUrl },
        substitutedKeys: ['imageUrl'],
        organisationId
      });

      this.setState({ imageUrl: preSignedImageUrl });
    } catch {
      // Silently handle error
    }
  };

  setUserSummaryNode = (node: ?HTMLElement) => {
    if (!node || this.userSummaryNode) { return; }

    this.userSummaryNode = node;
  };

  setNotificationsSummaryNode = (node: ?HTMLElement) => {
    if (!node || this.notificationsSummaryNode) { return; }

    this.notificationsSummaryNode = node;
  };

  setUserProfileHeaderNode = (node: ?HTMLElement) => {
    if (!node || this.userProfileHeaderNode) { return; }

    this.userProfileHeaderNode = node;
  };

  setNotificationsHeaderRef = (node: ?HTMLElement) => {
    if (!node || this.notificationsHeaderNode) { return; }

    this.notificationsHeaderNode = node;
  };

  toggleUserSummaryView = () => {
    this.setState(({ userSummaryVisible }) => ({
      userSummaryVisible: !userSummaryVisible
    }));
  };

  toggleNotificationsSummaryView = () => {
    this.setState(({ notificationsSummaryVisible }) => ({
      notificationsSummaryVisible: !notificationsSummaryVisible
    }));
  };

  handleGlobalClick: Function = (event: SyntheticInputEvent<EventTarget>) => {
    if (this.isClickInUserSummaryArea(event.target)) {
      return;
    }
    if (this.isClickInNotificationsSummaryArea(event.target)) {
      return;
    }
    if (this.isClickInUserProfileHeaderArea(event.target)) {
      this.hideNotificationsSummary();
      this.toggleUserSummaryView();
      return;
    }
    if (this.isClickInNotificationsHeaderArea(event.target)) {
      this.hideUserSummary();
      this.toggleNotificationsSummaryView();
      return;
    }
    this.hideUserSummary();
    this.hideNotificationsSummary();
  };

  hideUserSummary = () => {
    const { userSummaryVisible } = this.state;

    return userSummaryVisible
      && void this.setState({ userSummaryVisible: false });
  };

  hideNotificationsSummary = () => {
    const { notificationsSummaryVisible } = this.state;

    return notificationsSummaryVisible
      && void this.setState({ notificationsSummaryVisible: false });
  };

  handleSignOut = () => {
    const { ssoEnabled } = getSSOLocalStore();
    const { isGhosting } = this.state;

    clearSSOLocalStore();

    // If ghosting or not ssoEnabled, show HUB login page
    if (!ssoEnabled || isGhosting) {
      return void credentials.clear();
    }

    const { history } = this.props;

    history.push({ search: '?redirecting=true' });
    credentials.clear();

    return void redirectToSingleLogout();
  };

  handleStopGhosting = () => {
    const {
      original: {
        token,
        user,
        original = null
      },
      origURL: targetURL
    } = this.state;

    const { history } = this.props;

    clearSSOLocalStore();

    history.push(
      `/?ghost=${btoa(JSON.stringify({ clearGhost: true, token, user, original, targetURL }))}`
    );
  };

  isClickInUserSummaryArea =
    (clickedNode: Node) => this.userSummaryNode
      && this.userSummaryNode.contains(clickedNode);

  isClickInUserProfileHeaderArea =
    (clickedNode: Node) => this.userProfileHeaderNode
      && this.userProfileHeaderNode.contains(clickedNode);

  isClickInNotificationsSummaryArea =
    (clickedNode: Node) => this.notificationsSummaryNode
      && this.notificationsSummaryNode.contains(clickedNode);

  isClickInNotificationsHeaderArea =
    (clickedNode: Node) => this.notificationsHeaderNode
      && this.notificationsHeaderNode.contains(clickedNode);

  render() {
    const {
      userId,
      fullName,
      imageUrl,
      email,
      userSummaryVisible,
      notificationsSummaryVisible,
      isGhosting
    } = this.state;
    const {
      showSwitchOrganisationModal,
      selectedOrganisation,
      appSwitcherVisible,
      onCloseSwitchOrganisationModal,
      notifications
    } = this.props;

    return (
      <header>
        <div className="column global-header-container">
          <HelpButton />
          <NotificationsWrapper
            setNotificationsRef={this.setNotificationsHeaderRef}
          >
            <NotificationsSummary
              setNotificationsSummaryRef={this.setNotificationsSummaryNode}
              notificationsSummaryVisible={notificationsSummaryVisible}
              hideNotificationsSummary={this.hideNotificationsSummary}
              {...notifications}
            />
          </NotificationsWrapper>
          <CreditsWrapper>
            <CreditsInfo />
          </CreditsWrapper>
          <UserWrapper
            fullName={fullName}
            imageUrl={imageUrl}
            setUserProfileRef={this.setUserProfileHeaderNode}
            isGhosting={isGhosting}
          >
            <UserSummary
              userId={userId}
              imageUrl={imageUrl}
              fullName={fullName}
              isGhosting={isGhosting}
              email={email}
              handleSignOut={this.handleSignOut}
              handleStopGhosting={this.handleStopGhosting}
              setUserSummaryRef={this.setUserSummaryNode}
              hideUserSummary={this.hideUserSummary}
              userSummaryVisible={userSummaryVisible}
              showSwitchOrganisationModal={showSwitchOrganisationModal}
              selectedOrganisation={selectedOrganisation}
            />
          </UserWrapper>
          <SwitchOrganisationModal
            visible={appSwitcherVisible}
            onClose={onCloseSwitchOrganisationModal}
          />
        </div>
      </header>
    );
  }
}
