import React from 'react';
import { Route } from 'react-router-dom';
import { requiresPermission } from '@devsta/common-react';

export default function mapRoutes(routes: Array<Object>, requiredPermission?: string) {
  return routes.reduce((newRoutes, props) => {
    if (
      (!requiredPermission && !props.unprotected)
        || (requiredPermission && props.unprotected)) {
      return newRoutes;
    }

    const RouteComponent = !requiredPermission
      ? Route
      : requiresPermission({
        ComponentToRender: Route,
        requiredPermission,
        isRoute: true
      });

    return [
      ...newRoutes,
      <RouteComponent key={props.path} {...props} />
    ];
  }, []);
}
