// @flow

import React, { Component, Fragment } from 'react';
import { Button, Input, ActionButton } from '@devsta/ui-kit';
import type { RouterHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import LoginHeader from './LoginHeader';
import { onEnterPressed } from '../utils';
import '../styles/_forgot-password.scss';

type Props = {
  history: RouterHistory,
  forgotPassword: Function
};

type State = {
  email: string,
  emailSent: boolean,
  submitting: boolean
};

export default class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      emailSent: false,
      submitting: false
    };
  }

  onUpdateField = (fieldName: string) => ({ target: { value } }: { target: { value: string } }) => {
    this.setState({ [fieldName]: value });
  };

  goToLogin = () => {
    const { history } = this.props;

    history.push('/');
  };

  onSubmit = async () => {
    const { forgotPassword } = this.props;
    const { email } = this.state;

    if (!this.canSubmit(email)) { return; }

    this.setState({ submitting: true });

    const { error } = await forgotPassword(email);

    this.setState({
      submitting: false,
      emailSent: !error
    });
  };

  canSubmit = (email: string) => email && (email && isEmail(email));

  render() {
    const { email, emailSent, submitting } = this.state;

    return (
      <div className="forgot-password">
        {!emailSent ? (
          <Fragment>
            <ActionButton
              className="forgot-password__close-btn"
              onClick={this.goToLogin}
              labelText="CLOSE"
              icon="close"
              iconSize={16}
            />
            <LoginHeader
              title="Oh no.. this ain’t good!"
              subtitle="Enter your email below to reset your password.."
            />
            <Input
              data-cy="login-email-input"
              id="email-input"
              className="forgot-password__field"
              placeholder="Enter Email"
              onChange={this.onUpdateField('email')}
              onKeyDown={onEnterPressed({ onClick: this.onSubmit })}
              prefix="email"
              label="EMAIL"
              defaultValue={email}
              validate={value => !value || isEmail(value)}
              errorMessage="That doesn't look like an email address."
            />
            <Button
              busy={submitting}
              disabled={!this.canSubmit(email)}
              data-cy="reset-password-submit"
              className="forgot-password__submit"
              onClick={this.onSubmit}
            >
              Reset Password
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <LoginHeader
              title="Request Submitted"
              subtitle="Now check your email for that reset link!"
              hideIcon
            />
            <Button
              data-cy="back-to-login-submit"
              className="forgot-password__submit"
              onClick={this.goToLogin}
            >
              Back to Login
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}
