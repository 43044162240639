import 'react-app-polyfill/ie11';
import { init } from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import globalConfig from 'react-global-configuration';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { credentials, apiClient } from '@devsta/client-auth';
import isElectron from 'is-electron';
import config from './config';
import 'what-input';
import { AppContainer } from './common/containers';
import Routes from './routes';
import { version } from '../package.json';
import './styles/_main.scss';

if (config.sentryDSN && !isElectron()) {
  init({ dsn: config.sentryDSN, release: `${config.sentryReleasePrefix}${version}` });
}

const { user } = credentials.get() || {};

window.Intercom('boot', {
  app_id: 'uvq3bdav',
  ...user
});
globalConfig.set({ serviceEndpoint: config.serviceEndpoint });

const root: ?Element = document.getElementById('root');

const App = DragDropContext(HTML5Backend)(() => (
  <ApolloProvider client={apiClient({ apiEndpoint: config.apiEndpoint })}>
    <ApolloConsumer>
      {client => (
        <AppContainer makeQuery={client.query}>
          <Routes />
        </AppContainer>
      )}
    </ApolloConsumer>
  </ApolloProvider>
));


if (root != null) {
  ReactDOM.render(
    <App />,
    root
  );
}
