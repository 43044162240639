import { gql } from 'apollo-boost';

const FORGOT_PASSWORD_QUERY = gql`
    query forgotPassword($email: String!) {
        forgotPassword(email: $email)
    }
`;

export default () => ({
  mutation: FORGOT_PASSWORD_QUERY,
  module: 'users',
  buildVariables: (email: string) => ({ email })
});
