// @flow
import React from 'react';
import type { Node } from 'react';
import './styles/_credits-wrapper.scss';

function CreditsWrapper({ children }: { children: Node }) {
  return (
    <div className="global-credits-wrapper">
      {children}
    </div>
  );
}

export default CreditsWrapper;
