import React from 'react';
import { Input, Button, LinkButton, Icon } from '@devsta/ui-kit';
import '../styles/_login-modal.scss';

type LoginFormProps = {
  onCancel: Function,
  handleLogin: Function,
  updateEmail: Function,
  updatePassword: Function,
  busy: boolean,
  error?: string

};

export default function LoginForm({
  onCancel,
  handleLogin,
  updateEmail,
  updatePassword,
  busy,
  error
}: LoginFormProps) {
  return (
    <>
      <p className="login-form__title">Welcome Back!</p>
      <div className="login-form__body">
        <Input small label="EMAIL*" className="login-form__input" onChange={updateEmail} placeholder="Enter email" />
        <Input small label="PASSWORD*" type="password" className="login-form__input" onChange={updatePassword} placeholder="Enter password" />
        <hr className="login-form__horizontal-line" />
        <p className="login-form__text">
          Lorem ipsum dolor sit amet consectetur adipiscing elit sed do tempor incididunt eiusmod
          labore et maniqua sit aliqua lorem
        </p>
        <Button small className="login-form__button" onClick={handleLogin} busy={busy}>
          LOGIN
        </Button>
        <LinkButton className="login-form__cancel-button" onClick={onCancel}>
          CANCEL
        </LinkButton>
      </div>
      {error
        ? (
          <div className="login-form__body__error">
            <Icon name="alert" color="thunderBird" />
            <p>{error}</p>
          </div>
        ) : null
      }
    </>
  );
}
