// @flow

import React, { memo } from 'react';
import { Cell, Grid } from 'react-foundation';
import { Switch, Route } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import type { RouterHistory } from 'react-router-dom';

import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import '../styles/_login.scss';

type Props = {
  loginRequest: Function,
  forgotPassword: Function,
  history: RouterHistory
};

const Login = ({ loginRequest, forgotPassword, history }: Props) => (
  <div className="login">
    <Grid className="login__container" data-cy="login-container">
      <MediaQuery minWidth={640}>
        <Cell large={3} medium={3} small={12}>
          <div className="login__side-panel" />
        </Cell>
      </MediaQuery>
      <Cell large={9} medium={9} small={12}>
        <div className="login__right-content">
          <Switch>
            <Route
              path="/login/forgot"
              render={() => <ForgotPassword history={history} forgotPassword={forgotPassword} />}
            />
            <Route render={() => <LoginForm history={history} loginRequest={loginRequest} />} />
          </Switch>
        </div>
      </Cell>
    </Grid>
  </div>
);

export default memo<Props>(Login);
