import React from 'react';

import { BaseModal, Icon, Image } from '@devsta/ui-kit';
import { credentials } from '@devsta/client-auth';
import getOrgAlias from '@devsta/get-alias';

import Form from './Form';

import { login } from '../../../../../../utils';

import '../styles/_reauthenticate.scss';

type Props = {
  login: Function,
  visible: boolean
};

type State = {
  fullName: string,
  imageUrl: string,
  email: string,
  password: string,
  error: string,
  submitting: boolean
};

function clearLocalStore() {
  credentials.clear();
}

export default class Reauthenticate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user: { firstName, lastName, imageUrl, email } } = credentials.get();
    const fullName = `${firstName} ${lastName}`;

    this.state = {
      password: '',
      error: '',
      submitting: false,
      fullName,
      imageUrl,
      email
    };
  }

  /**
   * Updates a field in state
   *
   * @param {string} fieldName
   *
   * @return {Function}
   */
  onUpdateField = (fieldName: string) => (
    /**
     * @param {Object} event
     *
     * @return {void}
     */
    ({ target: { value } }: { target: { value: string } }) => {
      this.setState({ [fieldName]: value, error: '' });
    }
  );

  onSubmit = async () => {
    const { login: loginRequest } = this.props;
    const { email, password } = this.state;

    this.setState({ error: '', submitting: true });

    const result = await login(loginRequest, { email, password, alias: getOrgAlias() });

    if (!result) { return; }

    this.setState(result);
  }

  render() {
    const { visible } = this.props;
    const { error, submitting, fullName, imageUrl, email, password } = this.state;

    return (
      <div className="reauthenticate">
        <BaseModal
          visible={visible}
          onClose={clearLocalStore}
          className="reauthenticate__modal"
        >
          <div className="reauthenticate__modal__content">
            <div className="reauthenticate__modal__content__profile">
              <Image
                imageUrl={imageUrl}
                placeholderText={fullName}
                defaultImageIcon="user-profile-sq"
              />
              <div className="reauthenticate__modal__content__profile__text">
                <span className="reauthenticate__modal__content__profile__text__name">
                  {fullName}
                </span>
                <span className="reauthenticate__modal__content__profile__text__email">
                  {email}
                </span>
              </div>
            </div>
            <div className="reauthenticate__modal__content__form">
              <Form
                passwordValue={password}
                onSubmit={this.onSubmit}
                onUpdateField={this.onUpdateField}
                submitting={submitting}
                onCancel={clearLocalStore}
              />
              {
                error
                  ? (
                    <div className="reauthenticate__modal__content__error">
                      <Icon name="alert" color="thunderBird" size={15} />
                      <p>{error}</p>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </BaseModal>
      </div>
    );
  }
}
