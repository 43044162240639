import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
  NotFound as PureNotFound,
  UploaderProvider,
  NotificationsProvider,
  AuthorisationProvider,
  AuthenticationProvider,
  AuthorisationConsumer,
  Error,
  CreditsProvider
} from '@devsta/common-react';
import { ApolloConsumer } from 'react-apollo';

// Sub Apps
import Internal from 'hub3client-internal';
import Scripts from 'hub3client-scripts';
import Storyboards from 'hub3client-storyboards';
import UserSettings from 'hub3client-user-settings';
import Organisation from 'hub3client-organisation';
import MediaLibrary from 'hub3client-assets';
import VideoReview from 'hub3client-video-review';
import VideoTool from 'hub3client-video-tool';
import Traffic from 'hub3client-traffic';
import Inspirations from 'hub3client-inspirations';

// Containers & Layouts
import { CoreLayout } from '../layouts';
import { AuthProtected, ErrorBoundary, SSOAuth } from '../common/containers';

// App Pages
import Login from '../common/components/Login';
import Ghost from '../common/components/Login/components/Ghost';

// Utils
import { mapRoutes } from '../utils';

// Contexts
import AdminModeContext from '../common/contexts/AdminMode';

// Constants
import { REQUIRED_PERMISSIONS } from '../common/constants';

// Config
import config from '../config';

const {
  SCRIPTS,
  STORYBOARDS,
  DAM,
  ORGANISATION,
  INTERNAL,
  VIDEO_TOOL,
  VIDEO_REVIEW,
  INSPIRATIONS,
  USER_SETTINGS
} = REQUIRED_PERMISSIONS;

const NotFound = () => <PureNotFound />;

const scriptsRoutes = mapRoutes(Scripts, SCRIPTS);
const storyboardRoutes = mapRoutes(Storyboards, STORYBOARDS);
const internalRoutes = mapRoutes(Internal, INTERNAL);
const organisationRoutes = mapRoutes(Organisation, ORGANISATION);
const userSettingsAuthRoutes = mapRoutes(UserSettings, USER_SETTINGS);
const mediaLibraryRoutes = mapRoutes(MediaLibrary, DAM);
const videoReviewRoutes = mapRoutes(VideoReview, VIDEO_REVIEW);
const videoToolRoutes = mapRoutes(VideoTool, VIDEO_TOOL);
const trafficRoutes = mapRoutes(Traffic, INTERNAL);
const userSettingsPublicRoutes = mapRoutes(UserSettings);
const inspirationsRoutes = mapRoutes(Inspirations, INSPIRATIONS);

function getHomePage(adminMode: boolean, orgPreferences: { homepage?: string }) {
  const defaultAdminRedirect = '/internal/organisations';
  const videoToolRedirect = '/video-tool';

  if (adminMode) {
    return defaultAdminRedirect;
  }

  return orgPreferences && orgPreferences.homepage
    ? orgPreferences.homepage
    : videoToolRedirect;
}

/**
 * Creates the application route components
 *
 * @return {Element}
 */
export default function createRoutes() {
  window.Intercom('update');

  return (
    <ErrorBoundary>
      <Ghost>
        <NotificationsProvider skipNotifications={String(config.skipNotifications) === 'true'}>
          <ApolloConsumer>
            {client => (
              <Switch>
                <Route path="/error" render={() => <Error />} />
                {userSettingsPublicRoutes}
                <SSOAuth>
                  <AuthenticationProvider>
                    <AuthProtected
                      publicFallback={<Login />}
                      makeQuery={client.query}
                    >
                      <AuthorisationProvider>
                        <UploaderProvider apiClient={client}>
                          <AuthorisationConsumer>
                            {({ selectedOrganisation: {
                              permissions: orgPermissions,
                              preferences: orgPreferences
                            } = {} }) => (
                              <CreditsProvider>
                                <CoreLayout orgPermissions={orgPermissions}>
                                  <AdminModeContext.Consumer>
                                    {(adminMode: boolean) => (
                                      <Switch>
                                        <Redirect
                                          exact
                                          from="/"
                                          to={getHomePage(adminMode, orgPreferences)}
                                        />
                                        {scriptsRoutes}
                                        {storyboardRoutes}
                                        {internalRoutes}
                                        {organisationRoutes}
                                        {mediaLibraryRoutes}
                                        {videoReviewRoutes}
                                        {videoToolRoutes}
                                        {trafficRoutes}
                                        {userSettingsAuthRoutes}
                                        {inspirationsRoutes}
                                        <Route render={NotFound} />
                                      </Switch>
                                    )}
                                  </AdminModeContext.Consumer>
                                </CoreLayout>
                              </CreditsProvider>
                            )}
                          </AuthorisationConsumer>
                        </UploaderProvider>
                      </AuthorisationProvider>
                    </AuthProtected>
                  </AuthenticationProvider>
                </SSOAuth>
              </Switch>
            )}
          </ApolloConsumer>
        </NotificationsProvider>
      </Ghost>
    </ErrorBoundary>
  );
}
