import React, { Fragment } from 'react';
import { Button, Input, LinkButton } from '@devsta/ui-kit';

type Props = {
  passwordValue: string,
  onUpdateField: Function,
  onSubmit: Function,
  onCancel: Function,
  submitting: ?boolean
};

export default function Form({
  passwordValue,
  onUpdateField,
  onSubmit,
  submitting,
  onCancel
}: Props) {
  return (
    <Fragment>
      <p>{'We need to confirm that it\'s you'}</p>
      <form>
        <Input
          className="reauthenticate__form__input"
          placeholder="Password"
          value={passwordValue}
          type="password"
          onChange={onUpdateField('password')}
          prefix="padlock"
          label="PASSWORD"
          autoComplete="current-password"
        />
      </form>
      <Button
        className="reauthenticate__form__submit"
        disabled={!passwordValue}
        onClick={onSubmit}
        busy={submitting}
      >
        Login
      </Button>
      <LinkButton onClick={onCancel}>Not you?</LinkButton>
    </Fragment>
  );
}
