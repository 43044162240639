import React, { Component } from 'react';
import moment from 'moment';
import _memoize from 'lodash.memoize';
import type { RouterHistory as History } from 'react-router-dom';
import { Image, LinkButton } from '@devsta/ui-kit';
import { replaceBucketKeysWithUrlsInArray } from '@devsta/client-aws-s3';
import { getTimeRemaining, getLatestOutputVersion } from '../utils';
import { STATUS_COLORS_MAP } from '../../../constants/colors';
import '../styles/_outputs-list.scss';

type OutputsListProps = {
  getTrafficOutputs: Function,
  apolloClient: Object,
  history: History
}

type OutputsListState = {
  outputs: Array<Object>,
  count: number
}

export default class OutputsList extends Component<OutputsListProps, OutputsListState> {
  constructor(props: OutputsListProps) {
    super(props);

    this.state = {
      outputs: [],
      count: 0
    };
  }

  componentDidMount(): void {
    this.fetchOutputs();
  }

  fetchOutputs = async () => {
    const { getTrafficOutputs, apolloClient } = this.props;

    const { error, data } = await getTrafficOutputs();

    if (error || !data || !data.getTrafficOutputs) { return; }

    try {
      const outputsWithImages = await replaceBucketKeysWithUrlsInArray({
        apiClient: apolloClient,
        dataArray: data.getTrafficOutputs.outputs,
        substitutedKeys: [{ key: 'video', subKeys: [{ key: 'organisation', subKeys: ['imageUrl'] }] }]
      });

      this.setState({
        outputs: outputsWithImages,
        count: data.getTrafficOutputs.count
      });
    } catch {
      this.setState({ outputs: [], count: 0 });
    }
  };

  renderOutputItem = _memoize(({ outputId, versions, status, video }: Object) => {
    const { dueDate, appliedAddons: { RUSH_EDIT } = {} } = getLatestOutputVersion(versions);
    const { organisation, name } = video;

    const { hoursLeft, color } = getTimeRemaining({ dueDate, RUSH_EDIT });

    return (
      <div
        key={outputId}
        className="outputs-list__output"
      >
        <Image
          imageUrl={organisation.imageUrl}
          width="20px"
          height="20px"
        />
        <div className="outputs-list__output__info">
          <p className="outputs-list__output__info__name">{name}</p>
          <p
            style={{ color: STATUS_COLORS_MAP[status] }}
            className="outputs-list__output__info__status"
          >
            {status}
          </p>
        </div>
        <p
          style={{ color }}
          className="outputs-list__output__time-left"
        >
          {`${hoursLeft}hr${hoursLeft !== 1 ? 's' : ''}`}
        </p>
      </div>
    );
  });

  goToTraffic = () => {
    const { history } = this.props;

    history.push('traffic');
  };

  render() {
    const { outputs, count } = this.state;

    return (
      <div className="outputs-list">
        <p className="outputs-list__videos-due-count">{`VIDEOS DUE (${count})`}</p>
        <p className="outputs-list__date">
          {moment().format('Do MMMM YYYY')}
        </p>
        {outputs.map(this.renderOutputItem)}
        <LinkButton
          className="outputs-list__all"
          onClick={this.goToTraffic}
        >
          View All
        </LinkButton>
      </div>
    );
  }
}
