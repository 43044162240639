import REQUIRED_PERMISSIONS from './requiredPermissions';

const {
  SCRIPTS,
  STORYBOARDS,
  DAM,
  ORGANISATION,
  VIDEO_TOOL,
  INSPIRATIONS
} = REQUIRED_PERMISSIONS;

export const topNavLinks = [
  { label: 'Scripts', url: '/scripts', icon: 'script', requiredPermission: SCRIPTS },
  {
    label: 'Storyboards',
    url: '/storyboards',
    icon: 'storyboard',
    requiredPermission: STORYBOARDS
  },
  {
    label: 'Projects',
    url: '/video-tool',
    icon: 'video',
    requiredPermission: VIDEO_TOOL
  },
  {
    label: 'Asset Media Library',
    url: '/medialibrary',
    icon: 'medialibrary',
    requiredPermission: DAM
  },
  {
    label: 'Inspirations',
    url: '/inspiration',
    icon: 'inspiration',
    requiredPermission: INSPIRATIONS
  }
];

export const bottomNavLinks = [
  {
    label: 'Organisation',
    url: '/organisation',
    icon: 'company',
    requiredPermission: ORGANISATION
  }
];
