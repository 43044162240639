// @flow

import React, { memo } from 'react';
import { LinkButton } from '@devsta/ui-kit';
import './styles/_help-button.scss';

const HELP_URL = 'https://intercom.help/shootstahub/en';

const openHelpPage = () => {
  window.open(HELP_URL, '_blank');
};

function HelpButton() {
  return <LinkButton className="help-button" onClick={openHelpPage} small>Help Center</LinkButton>;
}

export default memo<{}>(HelpButton);
