import React from 'react';
import { Icon, Input, Button } from '@devsta/ui-kit';
import '../styles/_welcome-guest.scss';

type AnonymousUserFormProps = {
  displayLoginForm: Function,
  handleAnonymousUser: Function,
  updateFirstName: Function,
  updateLastName: Function,
  busy: boolean,
  error?: string
};

export default function AnonymousUserForm({
  displayLoginForm,
  handleAnonymousUser,
  updateFirstName,
  updateLastName,
  busy,
  error
}: AnonymousUserFormProps) {
  return (
    <>
      <div className="welcome-guest__header">
        <Icon name="projects" size={15} color="white" />
        <div className="welcome-guest__title">
          <p className="welcome-guest__primary-title">Hello there!</p>
          <p className="welcome-guest__secondary-title">
            Please enter your
            <b>&nbsp;FULL NAME&nbsp;</b>
            or if you have an account, log back into the HUB
          </p>
        </div>
      </div>
      <div className="welcome-guest__body">
        <div className="welcome-guest__body__left-content">
          <Input small label="FIRST NAME" className="welcome-guest__body__input" onChange={updateFirstName} />
          <Input small label="LAST NAME" className="welcome-guest__body__input" onChange={updateLastName} />
          <Button small className="welcome-guest__body__button" onClick={handleAnonymousUser} busy={busy}>
            START REVIEWING
          </Button>
          <p className="welcome-guest__body__text">OR</p>
          <Button small type="SECONDARY" className="welcome-guest__body__button" onClick={displayLoginForm}>
            LOG BACK IN
          </Button>
        </div>
        <div className="welcome-guest__body__right-content">
          <b className="welcome-guest__body__right-content__primary-text">
            A brighter, more accessible content library
          </b>
          <p className="welcome-guest__body__right-content__secondary-text">
            Lorem ipsum dolor sit amet consectetur adipiscing elit sed do tempor incididunt eiusmod
            labore et maniqua sit aliqua. Lorem ipsum dolor sit amet consectetur adipsicing elit sed
            do tempor incididunt eiusmod labore et maniqua sit aliqua. Lorem ipsum dolor sit amet.
          </p>
        </div>
      </div>
      <div className="welcome-guest__footer">
        <p className="welcome-guest__footer__link">NEED HELP?</p>
      </div>
      {error
        ? (
          <div className="welcome-guest__body__error">
            <Icon name="alert" color="thunderBird" />
            <p>{error}</p>
          </div>
        ) : null
      }
    </>
  );
}
