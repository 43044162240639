import React, { Component } from 'react';
import { configureScope } from '@sentry/browser';
import queryString from 'query-string';
import { credentials, authLocalStore } from '@devsta/client-auth';
import { withPublicLink } from '@devsta/common-react';
import type { Node } from 'react';
import type { Location } from 'react-router-dom';
import When from '../../../components/When';
import WelcomeGuestModal from '../../../components/WelcomeGuest';
import Reauthenticate from './Reauthenticate';
import config from '../../../../config';
import { redirectToSingleSignOn, getSSOLocalStore } from '../../../../utils';

type Props = {
  publicFallback?: Node,
  children?: Node,
  userIsRequired: boolean,
  hideWelcomeGuestLogin: Function,
  refreshToken: Function,
  login: Function,
  location: Location
};

type State = {
  hasToken: boolean,
  isReauthRequired: boolean
};

function setUserContext(user) {
  if (config.sentryDSN) {
    configureScope((scope) => {
      scope.setUser(user);
    });
  }
}

class AuthProtected extends Component<Props, State> {
  static defaultProps = {
    publicFallback: null,
    children: null
  };

  constructor(props: Props) {
    super(props);

    const { auth, user, token } = credentials.get();

    setUserContext(user);

    this.state = {
      hasToken: Boolean(token),
      isReauthRequired: Boolean(!auth && token)
    };

    credentials.listenToChange(this.handleCredentialsChange);
  }

  async componentDidMount() {
    const { hasToken, isReauthRequired } = this.state;
    const { refreshToken, location } = this.props;
    const { auth, user, original = null, origURL = null, ghost } = credentials.get();
    const { ssoEnabled } = getSSOLocalStore();

    if (isReauthRequired && ssoEnabled) {
      return void redirectToSingleSignOn(location.pathname);
    }

    if (!hasToken || !auth || !user || ssoEnabled) { return; }

    try {
      const result = await refreshToken();

      if (result.error) { return; }

      const { data: { refreshToken: refreshTokenData } } = result;

      const resultWithOriginal = {
        data: {
          refreshToken: {
            ...refreshTokenData,
            original,
            origURL,
            ghost
          }
        }
      };

      authLocalStore.save(resultWithOriginal);
    } catch {
      // Silently handle errors and maintain existing credentials.
    }
  }

  handleCredentialsChange = (usercrdls?: { auth: ?boolean, token: ?string }) => {
    if (!usercrdls) {
      return void this.setState({ hasToken: false, isReauthRequired: false });
    }

    const { auth, token } = usercrdls;

    this.setState({
      hasToken: Boolean(token),
      isReauthRequired: Boolean(!auth && token)
    }, () => {
      const { isReauthRequired } = this.state;
      const { location } = this.props;
      const { ssoEnabled } = getSSOLocalStore();

      if (!isReauthRequired || !ssoEnabled) { return; }

      redirectToSingleSignOn(location.pathname);
    });
  }

  render() {
    const {
      publicFallback,
      children,
      userIsRequired,
      hideWelcomeGuestLogin,
      login,
      location
    } = this.props;

    const { redirecting } = queryString.parse(location.search);

    if (redirecting) { return null; }

    const { hasToken, isReauthRequired } = this.state;

    if (userIsRequired) {
      return (
        <WelcomeGuestModal
          visible={userIsRequired}
          hideWelcomeGuestLogin={hideWelcomeGuestLogin}
        />
      );
    }

    return (
      <When condition={hasToken} failWith={publicFallback}>
        {
          isReauthRequired ? (
            <Reauthenticate visible login={login} />
          ) : children
        }
      </When>
    );
  }
}

export default withPublicLink(AuthProtected);
