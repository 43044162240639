const { INTERNAL } = require('./requiredPermissions').default;

export const topNavLinks = [
  {
    label: 'Global Organisations',
    url: '/internal/organisations',
    icon: 'web',
    requiredPermission: INTERNAL
  },
  {
    label: 'My Dashboard',
    url: '/traffic',
    icon: 'dashboard',
    requiredPermission: INTERNAL
  }
];
