// @flow

import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { AuthorisationConsumer } from '@devsta/common-react';
import type { RouterHistory as History } from 'react-router-dom';
import GlobalHeaderComponent from '../components/GlobalHeader';
import RemoteNotifications from '../../RemoteNotifications';

type GlobalHeaderContainerProps = {
  showSwitchOrganisationModal: Function,
  appSwitcherVisible: boolean,
  onCloseSwitchOrganisationModal: Function,
  history: History
};

function GlobalHeader(props: GlobalHeaderContainerProps) {
  return (
    <ApolloConsumer>
      {apolloClient => (
        <AuthorisationConsumer>
          {({ selectedOrganisation }) => (
            <RemoteNotifications selectedOrganisation={selectedOrganisation}>
              {notifications => (
                <GlobalHeaderComponent
                  selectedOrganisation={selectedOrganisation}
                  apolloClient={apolloClient}
                  notifications={notifications}
                  {...props}
                />
              )}
            </RemoteNotifications>
          )}
        </AuthorisationConsumer>
      )}
    </ApolloConsumer>
  );
}

export default withRouter(GlobalHeader);
