import React from 'react';
import { RequestProvider, AuthenticationConsumer } from '@devsta/common-react';
import AuthProtectedComponent from '../components/AuthProtected';
import { loginApi } from '../../../api';
import { refreshTokenApi } from '../api';

export default function AuthProtected(props: Object) {
  return (
    <AuthenticationConsumer>
      {
        ({ userIsRequired, hideWelcomeGuestLogin }) => (
          <RequestProvider
            requests={{
              login: loginApi(),
              refreshToken: refreshTokenApi()
            }}
          >
            {({ login, refreshToken }) => (
              <AuthProtectedComponent
                userIsRequired={userIsRequired}
                hideWelcomeGuestLogin={hideWelcomeGuestLogin}
                login={login}
                refreshToken={refreshToken}
                {...props}
              />
            )}
          </RequestProvider>
        )
      }
    </AuthenticationConsumer>
  );
}
