// @flow
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { LinkButton, Image } from '@devsta/ui-kit';
import { requiresPermission } from '@devsta/common-react';
import cx from 'classnames';
import UserAvailability from './UserAvailability';
import OutputsList from './OutputsList';
import '../styles/_user-summary.scss';

const OutputsListComponent = requiresPermission({
  ComponentToRender: OutputsList,
  requiredPermission: 'INTERNAL'
});

type UserSummaryProps = {
  userId: string,
  imageUrl?: string,
  fullName: string,
  isGhosting: boolean,
  email: string,
  handleSignOut: Function,
  handleStopGhosting: Function,
  setUserSummaryRef: Function,
  userSummaryVisible: boolean,
  hideUserSummary: Function,
  showSwitchOrganisationModal: Function,
  selectedOrganisation: Object,
  organisationMemberships: Array<Object>,
  isAdminMode: boolean
};

function UserSummary(props: UserSummaryProps) {
  const {
    userId,
    imageUrl,
    fullName,
    isGhosting,
    email,
    handleSignOut,
    handleStopGhosting,
    setUserSummaryRef,
    userSummaryVisible,
    hideUserSummary,
    showSwitchOrganisationModal,
    selectedOrganisation,
    organisationMemberships,
    isAdminMode
  } = props;

  const {
    imageUrl: orgImageUrl,
    name: orgName,
    permissions,
    organisationId
  } = selectedOrganisation;

  const currentMembership = organisationMemberships.find((
    ({ organisation }: Object) => organisationId === organisation.organisationId)
  );

  const hasAnotherWorkspace = organisationMemberships.length > 1;
  const userEditLink = `/user-settings/edit/${userId}`;

  return (
    <div
      className={cx('user-summary', {
        'user-summary--hidden': !userSummaryVisible
      })}
      ref={setUserSummaryRef}
    >
      <div className="user-summary__container">
        <Link to={userEditLink}>
          <div className="user-summary__container__usr-info">
            {imageUrl ? <Image width="75px" height="75px" imageUrl={imageUrl} alt="User Profile" backgroundPosition="top center" /> : void 0}
            <div className="user-summary__container__usr-info__text">
              <h4>{fullName}</h4>
              <h5>{email}</h5>
            </div>
          </div>
        </Link>
        {isGhosting && (
          <LinkButton className="user-summary__container__nav__link user-summary__container__nav__link--sign-out" onClick={handleStopGhosting}>Stop Ghosting</LinkButton>
        )}
        <hr className="user-summary__horizontal-rule" />
        <div className="user-summary__organisation-info">
          {orgImageUrl ? <Image width="30px" height="30px" imageUrl={orgImageUrl} backgroundSize="cover" /> : void 0}
          <div className={cx({ 'user-summary__organisation-info__description': Boolean(orgImageUrl) })}>
            <p className="user-summary__organisation-info__description__name">{orgName}</p>
          </div>
        </div>
        {
          currentMembership && currentMembership.roleName === 'SHOOTSTA_EDITOR' ? (
            <>
              <UserAvailability selectedOrganisation={selectedOrganisation} />
              <OutputsListComponent permissions={permissions} />
            </>
          ) : null
        }
        <hr className="user-summary__horizontal-rule" />
        <ul className="user-summary__container__nav">
          <li>
            <Link to="/organisation/" onClick={hideUserSummary} className="user-summary__container__nav__link">Manage Organisation</Link>
          </li>
          {!isAdminMode && hasAnotherWorkspace && (
            <li>
              <LinkButton onClick={showSwitchOrganisationModal} className="user-summary__container__nav__link">Sign in to another workspace...</LinkButton>
            </li>
          )}
        </ul>
        <hr className="user-summary__horizontal-rule" />
        <LinkButton className="user-summary__container__nav__link user-summary__container__nav__link--sign-out" onClick={handleSignOut}>Sign Out</LinkButton>
      </div>
    </div>
  );
}

export default memo<UserSummaryProps>(UserSummary);
