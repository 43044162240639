// @flow
import React, { memo } from 'react';
import { Icon } from '@devsta/ui-kit';
import type { Node } from 'react';
import './styles/_notifications-wrapper.scss';

type NotificationsWrapperProps = {
  children?: Node,
  setNotificationsRef: Function
};

function NotificationsWrapper({
  children,
  setNotificationsRef
}: NotificationsWrapperProps) {
  return (
    <div className="notifications-wrapper">
      <div
        className="notifications-wrapper__notification-box"
        ref={setNotificationsRef}
      >
        <Icon
          className="notifications-wrapper__notification-box__icon"
          name="notifications"
          size={15}
        />
      </div>
      {children}
    </div>
  );
}

NotificationsWrapper.defaultProps = {
  children: void 0
};

export default memo<NotificationsWrapperProps>(NotificationsWrapper);
