export default {
  SCRIPTS: 'SCRIPTS',
  STORYBOARDS: 'STORYBOARDS',
  DAM: 'DAM',
  INTERNAL: 'INTERNAL',
  ORGANISATION: 'ORGANISATION',
  VIDEO_TOOL: 'VIDEO_TOOL',
  VIDEO_REVIEW: 'VIDEO_REVIEW',
  INSPIRATIONS: 'INSPIRATIONS',
  USER_SETTINGS: 'USER_SETTINGS'
};
