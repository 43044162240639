// @flow

import React, { memo } from 'react';
import { Icon } from '@devsta/ui-kit';
import '../styles/_login-header.scss';

type LoginHeaderProps = {
  title: string,
  subtitle: string,
  hideIcon?: boolean
}

function LoginHeader({ title, subtitle, hideIcon }: LoginHeaderProps) {
  return (
    <div className="login-header">
      { !hideIcon && <Icon name="shootstacolour" size={35} /> }
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  );
}

export default memo<LoginHeaderProps>(LoginHeader);
