// @flow
import React, { memo } from 'react';
import { Icon, Image } from '@devsta/ui-kit';
import type { Node } from 'react';
import './styles/_user-wrapper.scss';

type UserWrapperProps = {
  imageUrl?: string,
  fullName: string,
  children?: Node,
  setUserProfileRef: Function,
  isGhosting: boolean
};

function UserWrapper({
  imageUrl,
  fullName,
  children,
  setUserProfileRef,
  isGhosting
}: UserWrapperProps) {
  return (
    <div className="user-wrapper-container">
      <div
        className="user-wrapper-container__userprofile-box"
        ref={setUserProfileRef}
      >
        {
          imageUrl
            ? (
              <Image
                width={25}
                height={25}
                imageUrl={imageUrl}
                alt="Organisation Profile"
                circular
              />
            )
            : void 0
        }
        {isGhosting && (
          <Icon
            className="user-wrapper-container__userprofile-box__icon__ghost"
            name="ghost"
            size={24}
            color="monkey"
          />
        )}
        <h6>{fullName}</h6>
        <Icon
          className="user-wrapper-container__userprofile-box__icon"
          name="arrowdown"
          size={10}
        />
      </div>
      {children}
    </div>
  );
}

UserWrapper.defaultProps = {
  imageUrl: '',
  children: void 0
};

export default memo<UserWrapperProps>(UserWrapper);
