function getAliasUrl(alias: string, { hostname, port }: { hostname: string, port: string }) {
  const portStr = port ? `:${port}` : '';

  // if no alias specified, return source URL
  if (!alias) {
    return `//${hostname}${portStr}`;
  }

  const reg = new RegExp('([0-9]+\\.)+[0-9]+');
  if (reg.test(hostname)) {
    return `//${hostname}${portStr}`;
  }

  const domainParts = hostname.split('.');

  if (domainParts.length <= 1) {
    return `//${hostname}${portStr}`;
  }

  if (['develop', 'staging'].includes(domainParts[0])) {
    return `//${hostname}${portStr}`;
  }

  const base = domainParts.slice(1).join('.');
  return `//${alias}.${base}${portStr}`;
}

module.exports = getAliasUrl;
