import React, { Component } from 'react';
import { credentials } from '@devsta/client-auth';
import { Switch } from '@devsta/ui-kit';
import { COLORS } from '../../../constants/colors';
import '../styles/_user-availavility.scss';

type UserAvailabilityProps = {
  getUserTeam: Function,
  updateUser: Function,
  isAvailable: boolean,
  userId: string
}

type UserAvailabilityState = {
  teamUsers: Array<Object>,
  isAvailable: boolean
}

export default class UserAvailability extends Component<
  UserAvailabilityProps,
  UserAvailabilityState
> {
  constructor(props: UserAvailabilityProps) {
    super();

    this.state = {
      isAvailable: props.isAvailable,
      teamUsers: []
    };
  }

  componentDidMount = () => {
    this.fetchTeam();
  };

  fetchTeam = async () => {
    const { getUserTeam } = this.props;

    const { data, error } = await getUserTeam();

    if (error || !data.getUserTeam) { return; }

    const { members } = data.getUserTeam;

    this.setState({
      teamUsers: members.map(({ user }) => user)
    });
  };

  toggleAvailability = async (isAvailable: boolean) => {
    const { updateUser } = this.props;

    const { user, ...rest } = credentials.get();

    const { error, data } = await updateUser({ isAvailable });

    if (error || !data.updateUser) { return; }

    this.setState({ isAvailable });

    credentials.set({
      ...rest,
      user: {
        ...user,
        isAvailable
      }
    });
  };

  render() {
    const { userId } = this.props;
    const { teamUsers, isAvailable } = this.state;

    const availableTeamUsersCount = teamUsers
      .map(user => (user.userId === userId ? { ...user, isAvailable } : user))
      .filter(user => user.isAvailable).length;

    const availableEditorsPercentage = teamUsers.length
      ? (availableTeamUsersCount / teamUsers.length) * 100
      : 0;

    const editorsProgressColor = availableTeamUsersCount === teamUsers.length
      ? COLORS.monkey
      : COLORS.orange;

    return (
      <div className="user-availability">
        <div className="user-availability__line">
          <div
            className="user-availability__line--progress"
            style={{ backgroundColor: editorsProgressColor, width: `${availableEditorsPercentage}%` }}
          />
        </div>
        <p className="user-availability__count">{`${availableTeamUsersCount}/${teamUsers.length}`}</p>
        <p className="user-availability__subtitle">Editors available</p>
        <hr className="user-availability__horizontal-rule" />
        <div className="user-availability__availability">
          <p>Availability</p>
          <Switch
            checked={isAvailable}
            onChange={this.toggleAvailability}
          />
        </div>
        <hr className="user-availability__horizontal-rule" />
      </div>
    );
  }
}
