// @flow
import React from 'react';
import { notificationTypeStrings } from '../../../../constants';

type Props = {
  notificationId: string,
  fullName: string,
  link: string,
  read: boolean,
  type: string,
  displayedCreatedAt: string,
  className: string,
  onLinkSelect: Function
};

export default function NotificationItem(props: Props) {
  const {
    notificationId,
    fullName,
    link,
    read,
    type,
    displayedCreatedAt,
    className,
    onLinkSelect
  } = props;

  return (
    <div className={`${className}__item ${!read ? 'unread' : ''}`}>
      <div className={`${className}__item__content`}>
        <div className={`${className}__item__content__text`}>
          <span>
            <strong>{fullName}</strong> {notificationTypeStrings[type]}
            <a target="_blank" href={link} rel="noopener noreferrer" onClick={onLinkSelect(notificationId)}>
              <strong>View</strong>
            </a>
          </span>
        </div>
        <p className={`${className}__item__content__updated`}>
          {displayedCreatedAt}
        </p>
      </div>
    </div>
  );
}
