import React, { Component } from 'react';
import cx from 'classnames';
import memoizeOne from 'memoize-one';

import { BaseModal } from '@devsta/ui-kit';
import getOrgAlias from '@devsta/get-alias';

import AnonymousUserForm from './AnonymousUserForm';
import LoginForm from './LoginForm';

import { login } from '../../../../utils';

import '../styles/_welcome-guest.scss';

type WelcomeGuestModalProps = {
  loginAnonymousUserRequest: Function,
  hideWelcomeGuestLogin: Function,
  loginRequest: Function,
  onClose: Function,
  visible: boolean
}

type WelcomeGuestModalState = {
  loginFormIsVisible: boolean,
  submitting: boolean,
  email?: string,
  password?: string,
  isClosed?: boolean,
  error?: string,
  lastName?: string,
  firstName?: string
}

export default class WelcomeGuestModal extends Component<
  WelcomeGuestModalProps,
  WelcomeGuestModalState
> {
  updateField = memoizeOne((field: string) => ({
    target: { value } }: { target: { value: string }
  }) => {
    this.setState({ [field]: value });
  });

  updateLoginFormIsVisible = memoizeOne(({ visible }: { visible: boolean }) => () => {
    this.setState({ loginFormIsVisible: visible, error: '' });
  });

  constructor(props: WelcomeGuestModalProps) {
    super(props);

    this.state = {
      loginFormIsVisible: false,
      submitting: false
    };
  }

  loginAnonymousUser = () => {
    const { firstName, lastName } = this.state;
    const { loginAnonymousUserRequest } = this.props;

    return this.login(
      loginAnonymousUserRequest,
      { firstName, lastName },
      { dataKey: 'loginAnonymousUser' }
    );
  }

  loginExistingUser = () => {
    const { email, password } = this.state;
    const { loginRequest } = this.props;

    return this.login(loginRequest, { email, password, alias: getOrgAlias() });
  }

  login = async (loginRequest: Function, payload: Object, dataKey?: { dataKey: string }) => {
    const { hideWelcomeGuestLogin } = this.props;

    this.setState({ error: '', submitting: true });

    const result = await login(loginRequest, payload, dataKey);

    if (!result) { return void hideWelcomeGuestLogin(); }

    this.setState(result);
  }

  handleClose = () => {
    this.setState({ isClosed: true });
  }

  render() {
    const { onClose, visible } = this.props;
    const { loginFormIsVisible, isClosed, submitting, error } = this.state;

    return (
      <div className="welcome-guest">
        <BaseModal
          visible={visible && !isClosed}
          onClose={this.handleClose}
          className={cx(
            'welcome-guest__modal',
            { 'welcome-guest__modal-login': loginFormIsVisible }
          )}
        >
          {
            loginFormIsVisible
              ? (
                <LoginForm
                  onCancel={this.updateLoginFormIsVisible({ visible: false })}
                  handleLogin={this.loginExistingUser}
                  updateEmail={this.updateField('email')}
                  updatePassword={this.updateField('password')}
                  busy={submitting}
                  error={error}
                />
              )
              : (
                <AnonymousUserForm
                  onClose={onClose}
                  updateFirstName={this.updateField('firstName')}
                  updateLastName={this.updateField('lastName')}
                  displayLoginForm={this.updateLoginFormIsVisible({ visible: true })}
                  handleAnonymousUser={this.loginAnonymousUser}
                  busy={submitting}
                  error={error}
                />
              )
          }
        </BaseModal>
      </div>
    );
  }
}
